'use client';

import ReactDOM from 'react-dom';

/**
 * Next.js does not support some link rel types that we need to use
 * the official suggested workaround is to use ReactDOM api.
 * https://nextjs.org/docs/app/api-reference/functions/generate-metadata#unsupported-metadata
 */

export function PreloadResources() {
  ReactDOM.preconnect('https://service.mundodanone.com.br');

  ReactDOM.preconnect('https://connect.facebook.net');
  ReactDOM.prefetchDNS('https://connect.facebook.net');

  ReactDOM.preconnect('https://www.googletagmanager.com/');
  ReactDOM.prefetchDNS('https://www.googletagmanager.com/');

  ReactDOM.preconnect('https://adservice.google.com');
  ReactDOM.prefetchDNS('https://adservice.google.com');

  ReactDOM.preconnect('https://media.mundodanone.com.br/');
  ReactDOM.prefetchDNS('https://media.mundodanone.com.br/');

  // vwo
  ReactDOM.preconnect('https://dev.visualwebsiteoptimizer.com');

  return null;
}
