import { ReactNode, memo, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useStore } from 'src/hooks/useStore';
import { toReactIntl } from 'src/utils/formatLocale';
import ptBR from '@lang/ptbr.json';

export interface ITranslateContextProvider {
  children: ReactNode;
}

const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_DEFAULT_LOCALE as string;

const messages = {
  'pt-BR': ptBR
};

export const TranslateProvider = memo(
  ({ children }: ITranslateContextProvider) => {
    const { currentStore } = useStore();

    const language = useMemo(() => {
      const storeLocale = currentStore?.locale;
      if (storeLocale) {
        return toReactIntl(storeLocale);
      }
      return DEFAULT_LOCALE;
    }, [currentStore]);

    return (
      <IntlProvider
        key={language}
        defaultLocale={DEFAULT_LOCALE}
        locale={language}
        messages={messages[language]}
      >
        {children as any}
      </IntlProvider>
    );
  }
);

TranslateProvider.displayName = 'TranslateProvider';
