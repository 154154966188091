/**
 * Convert locale from react-intl BCP 47 language code, to Magento standard
 */
export const fromReactIntl = (string: string) => {
  return string.replace('-', '_');
};

/**
 * Convert locale from Magento standard to react-intl BCP 47 language code
 */
export const toReactIntl = (string: string) => {
  return string.replace('_', '-');
};
