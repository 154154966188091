'use client';

import { ScriptTags } from '@components/common';
import NewRelic from '@pages/newrelic_snippet';

export default function ScriptTagWrapper() {
  return (
    <>
      <ScriptTags />
      <NewRelic />
    </>
  );
}
