import { ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import {
  ApolloNextAppProvider,
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink
} from '@apollo/experimental-nextjs-app-support/ssr';
import { Persistence } from '@utils';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions, message, locations, path }) => {
      if (
        extensions?.category === 'graphql-authorization' &&
        localStorage.getItem('@cart/id') &&
        localStorage.getItem('signin_token')
      ) {
        localStorage.removeItem('@cart/id');
        localStorage.removeItem('signin_token');
        history.go(0);
      }
      if (
        process.env.NODE_ENV !== 'production' &&
        typeof window !== 'undefined'
      ) {
        window.console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      }
    });
  // eslint-disable-next-line no-console
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists.
  const token = Persistence.getItem('signin_token');
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

// Create a new store link to include store codes and currency in the request
const storeLink = setContext((_, { headers }) => {
  const storeCurrency = Persistence.getItem('store_view_currency') || null;
  const storeCodePersisted = Persistence.getItem('store_view_code');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      store: storeCodePersisted || 'default',
      ...headers,
      ...(storeCurrency && { 'Content-Currency': storeCurrency })
    }
  };
});

function makeClient() {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_MAGENTO_BACKEND_BASE_URL,
    credentials: 'same-origin'
  });

  const ssrHttpLink = ApolloLink.from([
    new SSRMultipartLink({ stripDefer: true }),
    httpLink
  ]);

  const netLink = typeof window === 'undefined' ? ssrHttpLink : httpLink;

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(),
    link: ApolloLink.from([errorLink, authLink, storeLink, netLink])
  });
}

export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
