import { useMemo, useState, useEffect, memo } from 'react';
import { useAdminUser, useAuth } from '@hooks';

export const LACHeader = memo(() => {
  const { user } = useAuth();
  const { isAdmin } = useAdminUser();
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    setIsSSR(false);
  }, []);

  const userSignedOn = useMemo(() => {
    return `${user.firstname || ''} ${user.lastname || ''} (${
      user.email || ''
    })`;
  }, [user]);

  return !isSSR && isAdmin ? (
    <div className={`w-full bg-primary-darkest`}>
      <div className="w-full lg:max-w-wrapper mx-auto text-white flex flex-col items-end p-4">
        <p>Você está logado na conta de:</p>
        <b className="ml-1">{userSignedOn}</b>
      </div>
    </div>
  ) : (
    <></>
  );
});

LACHeader.displayName = 'LACHeader';
